var googleMap = (function($) {

    var apiKey = 'AIzaSyC_JU2CkWsTRYmH6S_Kh5MFZg3cwJ1iXPA';
    var s = {
      mapContainer: '#google-map'
    };
    var id = {
        mapContainer: 'google-map'
    };

    return {

        init: function() {
            this.initGoogleMap();
        },

        events: function() {

        },

        initGoogleMap: function () {
            var container = document.getElementById(id.mapContainer);
            var coordinates = (typeof gmap__coordinates !== 'undefined') ? gmap__coordinates : {lat: 59.965269, lng: 30.283341};

            if (container) {
                var map = new google.maps.Map(container, {
                    center: coordinates,
                    zoom: 16,
                    styles: mapStyles,
                    disableDefaultUI: true
                });
                var marker = new google.maps.Marker({
                    position: coordinates,
                    map: map,
                    title: 'Кухни Nolte'
                });
            }
        }
    };

})(jQuery);

var mapStyles = [{"stylers":[{"visibility":"on"},{"saturation":-100},{"gamma":0.54}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"water","stylers":[{"color":"#4d4946"}]},{"featureType":"poi","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels.text","stylers":[{"visibility":"simplified"}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.local","elementType":"labels.text","stylers":[{"visibility":"simplified"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"gamma":0.48}]},{"featureType":"transit.station","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"geometry.stroke","stylers":[{"gamma":7.18}]}];
